<template>
    <div class="news">
        <nav-bar class="nav-bar" id="titleArea" ref="nav"></nav-bar>
        <div class="news-content d-flex" id="middleArea">
            <div class="content-right">
                <div class="head">案例展示</div>
                <div class="case">

                    <div v-for="(item, index) in caseShows" class="row">
                        <img :src='item.img' />
                        <div class="desc">{{ item.title }}</div>
                    </div>
                </div>
                <div class="head mt-2">案例集锦</div>
                <div class="case">
                    <img v-for="(item, index) in caseCollections" :src='item.img' class="logo" />
                </div>
            </div>
        </div>
        <bottom-bar class="home-bottom" />
    </div>
</template>
<script>

import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import { CaseList } from '@/api/news'
import { getInfo } from '@/api/login'

import NavBar from '../../components/content/navBar/NavBar'
import BottomBar from '../../components/content/bottomBar/BottomBar'

import { getToken, setToken, removeToken } from '@/utils/cookies.js'

export default {
    name: 'News',
    components: {
        NavBar,
        BottomBar
    },
    data() {
        return {
            users: {},//用户信息
            total: 0,//总数
            caseShows: [],//案例展示
            caseCollections: [],//案例集锦
        }
    },
    created() {
        //用户信息
        this.userInfo();
        //列表
        this.onCaseList();
    },
    methods: {
        //查看详情
        details(id) {
            this.$router.push({
                name: '/newsDetail',
                params: {
                    "id": id,
                    "mdd": this.$refs.nav.mdd
                }
            });
        },
        //用户信息
        userInfo() {
            var token = getToken();
            if (token) {
                getInfo(token).then(response => {
                    if (response.status == 200) {
                        this.users = response.data;
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                })
            }
        },
        //列表
        onCaseList() {
            this.listLoading = true;
            CaseList().then(response => {
                if (response) {
                    if (response.status == 200) {
                        this.caseShows = response.data.shows;
                        this.caseCollections = response.data.collections;
                    } else {
                        MessageBox.alert(response.message, "错误", {
                            confirmButtonText: '确定',
                            type: 'error'
                        })
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                }
            })
        }
    },
    mounted() {
        this.$refs.nav.mdd = 5;
        localStorage.setItem("mdd", 5);
        const middleArea = document.getElementById('middleArea');
        const mheight = document.documentElement.clientHeight;
        const titleHeight = document.getElementById('titleArea').clientHeight;
        middleArea.style.minHeight = (Number(mheight) - (Number(titleHeight) + 50)) + 'px';
    },
}
</script>
<style scoped lang="less">
.news {
    min-width: 1000px;

    .nav-bar {
        .bar-icon {
            border-radius: 50%;
            vertical-align: -4px;
            margin-right: 7px;
            width: 1.05rem;
            height: 1.05rem;
        }
    }

    .news-content {
        padding-left: 9.1rem;
        padding-right: 9.1rem;
        margin-top: 1.1rem;
        padding-bottom: 1.8rem;

        .content-left {
            width: 19%;
            background-color: #fff;
            margin-right: 0.8rem;
            height: fit-content;

            .left-item {
                border-bottom: 1px solid #E7E7E7;
                padding: 1rem 0.95rem 1rem 1rem;

                &:last-child {
                    border-bottom: none;
                }

                .type {
                    color: #6A6E76;
                    font-size: 0.65rem;
                    font-weight: 600;
                    width: calc(100% - 2rem);
                }

                .active {
                    color: #3E7EFF;
                }

                .arrow {
                    width: 0.5rem;
                    height: 0.5rem;
                }
            }
        }

        .content-right {
            flex: 1;

            .head {
                color: #303133;
                font-weight: 600;
                font-size: 1.5rem;
                margin-bottom: 1.5rem;
            }

            .mt-2 {
                margin-top: 2rem;
            }

            .case {
                display: flex;
                flex-wrap: wrap;

                .row {
                    background-color: #fff;
                    border-radius: 12px;
                    width: calc((100% - 3rem*2) / 3);
                    transition: transform 0.5s;
                    margin-right: 3rem;
                    margin-bottom: 40px;
                    box-shadow: 0 2px 20px 0 hsla(0, 0%, 51.4%, .2);

                    img {                        
                        width: 100%;
                        border-top-left-radius: 12px;
                        border-top-right-radius: 12px;
                        //height: 9rem
                        height: 70%;
                    }

                    .desc {
                        padding: 0.6rem 0.6rem 0.7rem;
                        color: #666;
                        font-size: 0.95rem;
                    }

                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }

                .logo {
                    width: calc((100% - 6rem*2) / 3);
                    margin-right: 6rem;
                    border-radius: 12px;
                    height: 4em;
                    margin-bottom: 10px;

                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
</style>